import wx from 'weixin-js-sdk'
import axios from 'axios'

const wxApi = {
    wxRegister (callback) {
        axios.get('https://wcyc.nd-cohen.com/system/defArticle/getWxConfig?url=' + window.location.href.split('#')[0])
        .then(res => {
            // console.log(res)
            let data = res.data
            if (data.code === 0) {
                let config = data.data
                // config.debug = true
                config.jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData'] // 需要使用的JS接口列表
                wx.config(config)
                wx.ready(() => {
                    if (callback) callback()
                })
            }
        }).catch((err) => { 
            console.log(err)
        })
    },
    shareAppMessage (option) { // 分享给朋友 分享到QQ
      wx.updateAppMessageShareData({ 
        title: option.title, // 分享标题
        desc: option.desc, // 分享描述
        link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: option.imgUrl || 'https://wcyc.nd-cohen.com/profile/upload/common/share.jpg', // 分享图标
        success () {
          if (option.success) option.success()
          // 设置成功
        }
      })
    },
    shareTimeline (option) { // 分享到朋友圈 分享到QQ空间
      wx.updateTimelineShareData({ 
        title: option.title, // 分享标题
        link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: option.imgUrl || 'https://wcyc.nd-cohen.com/profile/upload/common/share.jpg', // 分享图标
        success () {
          if (option.success) option.success()
          // 设置成功
        }
      })
    }
}
  
export default wxApi